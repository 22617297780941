import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '..';
import classes from './Home.module.scss';
import { PwdDialog } from './PwdDialog';
import classNames from "classnames/bind";
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { OptionType } from '../types/Props';
import BasicSelect from '../components/UI/BasicSelect';
import { SelectChangeEvent } from '@mui/material';


const cx = classNames.bind(classes);

const Home: React.FC = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const {token, error} = useSelector((state: RootState) => state.auth);
    const [employee, setEmployee] = useState();
    const [open, setOpen] = useState(false);
    const [resetWarning, setResetWarning] = useState(false);
    const imgUrl = '/banner_home.png';
    const LANGUAGES: OptionType[] = [
      {
        value: "es",
        label: t('Espanol')
      },
      {
        value: "en",
        label: t('Ingles')
      }
    ];

    useEffect(() => {
        // Default values
        let stUser = localStorage.getItem("user");
        let user = JSON.parse(stUser!);
        //
        setEmployee(user?.name);

        //const queryParams = new URLSearchParams(location.search);
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get('reset') === 'true') {
          setOpen(true);
          setResetWarning(true);
        }

    }, []);

    const onChangePassword = () => {
      setOpen(true);
    };

    const handleOk = () => {
      setOpen(false);
      setResetWarning(false);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleMonthChange = (event: SelectChangeEvent<string>) => {
      const selectedLanguage = event.target.value;
      i18n.changeLanguage(selectedLanguage);
    };

  return (
    <Fragment>
<div className={classes.home} style = {{ backgroundImage: `url(${imgUrl})` ,
                backgroundSize: 'cover', 
                color: 'white',
                width: '100%',
                maxWidth: '100vw',
                paddingLeft: '2rem',
                paddingRight: '2rem',
                marginBottom: '1rem',
                minHeight: '200px'
                }}>
    <div>
    <h2>{t('Bienvenid@ a SIPAC')}</h2>
    <span>{t('Sistema Interno de Partes')}</span>
    <p>
        {t('Lea atentamente las instrucciones para rellenar los partes')}
    </p>
    </div>
    {token &&<div className='d-flex flex-column justify-content-center'>
        <div>{t('Empleado')}</div>
        <div>
            <b>{employee}</b>
            &nbsp;&nbsp;
            <button onClick={onChangePassword}  
            className='btn btn-labeled btn-danger'>
              <span className='btn-labeled'
                ><i className="fa fa-key"></i></span>{t('Cambiar clave')}
            </button>
          <BasicSelect onChange={handleMonthChange} value={i18n.language}
            className={`${classes.clsSelec}`} nullOption={false}
            options={LANGUAGES} />
        </div>
    </div>}
  </div>
    
    <div className={classes.activities}>
        <b>{t('Parte de actividades')}</b>
        <ul className={classes.bloque}>

        <li>
            <Trans i18nKey="actividades.linea1">
            Seleccione el <b>Proyecto</b> y <b>Concepto</b> al que desee imputar horas. (Pulsando sobre el icono 'P' podrá seleccionarlos por nombre)
            </Trans>
            </li>
            <li>
            <Trans i18nKey="actividades.linea2">
            Rellene para cada dia el <b>número de horas</b> para el Proyecto y Concepto seleccionado en la casilla correspondiente
            </Trans>
            </li>
            <li>
            <Trans i18nKey="actividades.linea3">
            Si necesita añadir <b>observaciones</b> para algún día haga doble click sobre la casilla del día
            </Trans>
            </li>
            <li>
            <Trans i18nKey="actividades.linea4">
            Para <b>crear un nuevo Proyecto o Concepto</b>, pulse el botón Añadir y repita los pasos anteriores
            </Trans>
            </li>
            <li>
            <Trans i18nKey="actividades.linea5">
            Para <b>borrar un Proyecto y Concepto</b>, pulse el botón de Borrado (X) de la linea correspondiente
            </Trans>
            </li>
            <li>
            <Trans i18nKey="actividades.linea6">
            Las imputaciones se podrán guardar en <b>modo 'borrador'</b> pulsando el botón Guardar
            </Trans>
            </li>
            <li>
            <Trans i18nKey="actividades.linea7">
            Cuando termine y verifique el parte mensual, pulse el botón <b>Enviar</b>
            </Trans>
            </li>
            <li>
            <Trans i18nKey="actividades.linea8">
            Las <b>Ausencias y Vacaciones</b> se imputarán con el proyecto correspondiente y con el número de horas habituales por día
            </Trans>
            </li>
            <li>
            <Trans i18nKey="actividades.linea9">
            Cualquier <b>falta</b> deberá ser debidamente justificada (parte médico, justificante de examen, ...)
            </Trans>
            </li>
            
        </ul>
    </div>
    <div className={classes.expenses}>
        <b>{t('Parte de gastos')}</b>
        <ul className={classes.bloque}>
            <li>
            <Trans i18nKey="gastos.linea1">
                Seleccione el <b>Proyecto</b> y <b>Concepto</b> al que desee imputar gastos 
            </Trans>
            </li>
            <li>
            <Trans i18nKey="gastos.linea2">
                Seleccione el <b>rango de fechas</b> de imputación del gasto (no rellene la Fecha Hasta si es un gasto puntual) 
            </Trans>
            </li>
            <li>
            <Trans i18nKey="gastos.linea3">
                Seleccione el <b>Tipo de Gasto, Unidades, Importe</b> (opcional) y <b>Total</b>, y si corresponde, la <b>Descripción</b>
            </Trans>
            </li>
            <li>
            <Trans i18nKey="gastos.linea4">
                Para crear un <b>nuevo Gasto</b>, pulse el botón Añadir y repita los pasos anteriores
            </Trans>
            </li>
            <li>
            <Trans i18nKey="gastos.linea5">
            Para <b>borrar un Gasto</b>, pulse el botón de Borrado (X) de la linea correspondiente
            </Trans>
            </li>
            <li>
            <Trans i18nKey="gastos.linea6">
            Los gastos se podrán guardar en <b>modo 'borrador'</b> pulsando el botón Guardar
            </Trans>
            </li>
            <li>
            <Trans i18nKey="gastos.linea7">
            Cuando termine y verifique el parte mensual, pulse el botón <b>Enviar</b>
            </Trans>
            </li>
            <li>
            <Trans i18nKey="gastos.linea8">
            Cualquier Gasto deberá ser debidamente <b>justificado</b>, mediante factura o documento similar 
            </Trans>
            </li>
            
        </ul>
        <p>&nbsp; </p>
    </div>
    <div className={classes.holidays}>
    <b>{t('Solicitud de vacaciones')}</b> 
    <ul className={classes.bloque}>

    <li>
            <Trans i18nKey="vacaciones.linea1">
            Entrar en <b>“solicitud vacaciones”</b> y marcar los días deseados haciendo click sobre los recuadros.
            </Trans>
            </li>
            <li>
            <Trans i18nKey="vacaciones.linea2">
            La solicitud se podrá guardar en modo <b>'borrador'</b> pulsando el botón <b>Guardar</b>.
            </Trans>
            </li>
            <li>
            <Trans i18nKey="vacaciones.linea3">
            Para proceder al envío de la solicitud pulse el botón <b>Enviar</b>.
            </Trans>
            </li>
            <li>
            <Trans i18nKey="vacaciones.linea4">
            No se podrá disfrutar de vacaciones que no estén debidamente solicitadas y <b>visadas por el gerente</b>.
            </Trans>
            </li>
            <li>
            <Trans i18nKey="vacaciones.linea5">
            Una vez se haya visado su solicitud, en ‘Parte de Actividad’ <b>añadimos el proyecto “vacaciones”</b>, y éstas se cargarán automáticamente.
            </Trans>
            </li>
        
    </ul>
    </div>
    <div className={cx({
                'InfoMessage': true,
                'msg': error
              })} >
          {error}
    </div>
    <PwdDialog open={open} resetWarning={resetWarning}
      onOkDialog={handleOk} onCloseDialog={handleClose}></PwdDialog>
    </Fragment>
  );
};

export default Home;
