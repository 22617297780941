// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectMonth_employee_icon__80qBU {\n  background-size: cover;\n  height: 2rem;\n  border: 1px solid black;\n  border-radius: 50%;\n  width: 2rem;\n  margin: 0 1rem 0 0;\n}\n\n.SelectMonth_quote__RLSU2 p {\n  font-size: 2.5rem;\n}\n\n.SelectMonth_quote__RLSU2 figcaption {\n  font-style: italic;\n  font-size: 1.5rem;\n  text-align: right;\n  color: #a1e0e0;\n}\n\n.SelectMonth_card_user_activities__pa01i {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-content: center;\n  justify-content: flex-start;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/select-month/SelectMonth.module.scss"],"names":[],"mappings":"AAME;EACE,sBAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;AALJ;;AAQE;EACE,iBAAA;AALJ;;AAQE;EACE,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AALJ;;AAQC;EACG,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,qBAAA;EACA,2BAAA;EACA,mBAAA;AALJ","sourcesContent":["\n  .card {\n    // max-width: 30rem;;\n    // background-color: white;\n  }\n\n  .employee_icon {\n    background-size: cover;\n    height: 2rem;\n    border: 1px solid black;\n    border-radius: 50%;\n    width: 2rem;\n    margin: 0 1rem 0 0;\n  }\n  \n  .quote p {\n    font-size: 2.5rem;\n  }\n  \n  .quote figcaption {\n    font-style: italic;\n    font-size: 1.5rem;\n    text-align: right;\n    color: #a1e0e0;\n  }\n\n .card_user_activities {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: flex-start;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"employee_icon": "SelectMonth_employee_icon__80qBU",
	"quote": "SelectMonth_quote__RLSU2",
	"card_user_activities": "SelectMonth_card_user_activities__pa01i"
};
export default ___CSS_LOADER_EXPORT___;
