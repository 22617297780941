
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { inicializeMonths } from '../../lib/Utils';
import Card from '../UI/Card';
import classes from './SelectMonth.module.scss';
import BasicSelect from '../UI/BasicSelect';
import { SelectChangeEvent } from '@mui/material';

const SelectMonth: React.FC<{ title: string; employee: string; name: string, monthYear: string; rol: number; monthNotClosed: number;
  onChangeMonth: (month: any) => void;
  children?: React.ReactNode }> = (props) => {
  const { t } = useTranslation();

  const months = inicializeMonths(props.rol, props.monthNotClosed);

  //const [selectedMonth, setSelectedMonth] = useState<OptionType>(months[1]);
  const [selectedMonth, setSelectedMonth] = useState(props.monthYear ? props.monthYear.toString() : months[1].value);

  const handleMonthChange = (event: SelectChangeEvent<string>) => {
    setSelectedMonth(event.target.value);
    props.onChangeMonth(event.target.value);
  };

  return (
    <Card clsCard={classes.card}>
      <h3>{props.title}</h3>
      <div className={classes.card_user_activities}>
          <img
              src="login_head.png"
              title={props.name}
              alt={props.name}
              className={classes.employee_icon}
          />
          <figcaption>{props.name}</figcaption>
      </div>
        <BasicSelect onChange={handleMonthChange} value={selectedMonth}
          className={`${classes.clsSelec}`} labelNone={t('Seleccione mes')} nullOption={false}
          options={months} />
    </Card>
  );
};

export default SelectMonth;
