import axios from 'axios';
import BASE_URL from '../Config';

const instance = axios.create({
    baseURL: BASE_URL
});

// Alter defaults after instance has been created
//instance.defaults.headers.common['Authorization'] = localStorage.getItem('token');

// Add a request interceptor
instance.interceptors.request.use(function (config: any) {
    if (!config.url.includes('login')) {
        const token = `Bearer ${localStorage.getItem('token')}`;
        config.headers.Authorization =  token;
    }

    return config;
});

instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );

export default instance;