import { Route, Routes } from 'react-router-dom';

import NotFound from './pages/NotFound';
import Layout from './components/layout/Layout';

import { useSelector } from 'react-redux';

import { RootState } from './index';
import Auth from './pages/auth/Auth';
import Activities from './pages/activities/Activities';
import Home from './pages/Home';
import Expenses from './pages/expenses/Expenses';
import Employees from './pages/employees/Employees';
import Holidays from './pages/holidays/Holidays';
import Print from './pages/Print';

function App() {
  const token = useSelector((state: RootState) => state.auth.token);

  if (token === null) {
    return (
      <Layout>
        <Routes>
          <Route path="/auth" element={<Auth />} />
          <Route path='*' element={<Auth />} />
        </Routes>
      </Layout>
    );
  }

  return (
    <Layout>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/activities' element={<Activities />} />
        <Route path='/expenses' element={<Expenses />} />
        <Route path='/employees' element={<Employees />} />
        <Route path='/holidays' element={<Holidays />} />
        <Route path='/holidays/manager' element={<Holidays />} />
        <Route path='/print' element={<Print />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Layout>
  );
}

export default App;
