import { Fragment, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addExpense, putExpenses, fetchExpenses, expensesInit } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import SelectMonth  from '../../components/select-month/SelectMonth';
import Toolbar from '../../components/toolbar/Toolbar';

import LoadingSpinner from '../../components/UI/LoadingSpinner';


import useHttp from '../../hooks/use-http';
import { getProjects, getExpenseTypes } from '../../lib/api';

import { RootState } from '../..';

import classNames from "classnames/bind";
import classes from './Expenses.module.scss';
import { employeeReducer, initEmployee } from '../../lib/Utils';
import ExpensesList from '../../components/expense/ExpensesList';
import { useLocation, useNavigate } from 'react-router-dom';
import { Expense } from '../../types/Expense';
import { OptionType } from '../../types/Props';

const cx = classNames.bind(classes);

const Expenses: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  
  const dispatch = useDispatch();
  const { part, loading, error } = useSelector((state: RootState) => state.expense);
  const {rol, monthNotClosed } = useSelector((state: RootState) => state.auth);

  const [userState, dispatchEmployee] = useReducer(employeeReducer, initEmployee);

  const { sendRequest, status, data: projects, error: error1 } = useHttp(
    getProjects, true
  );
  const { sendRequest: sendRequest2, status: status2, data: concepts, error: error2 } = useHttp(
    getExpenseTypes, false
  );

  useEffect(() => {
    // valores por defecto
    let d = new Date();
    let stUser = localStorage.getItem("user");
    let user = JSON.parse(stUser!);
    //
    const queryParams = new URLSearchParams(location.search);
    const employee = queryParams.get('employee') || user.employee;
    const name = queryParams.get('name') || user.name;
    const month = queryParams.get('month') === '0' ? 0 
              : Number(queryParams.get('month') || d.getMonth());
    const year = Number(queryParams.get('year')) || d.getFullYear();
  
    dispatchEmployee({ type: 'USER_CHANGE', 
        employee, 
        name,
        year, 
        month});

    // Projects
    sendRequest({employee, year, month: month + 1});
    // Expense types
    sendRequest2({employee, year, month: month + 1});

    // init model
    dispatch(expensesInit(
      employee, 
      year, 
      month + 1));
  }, [dispatch, location.search, sendRequest, sendRequest2]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const employee = userState.employee || queryParams.get('employee');
    const month = userState.month === 0 ? 0 
          : userState.month || Number(queryParams.get('month'));
    const year =  userState.year || Number(queryParams.get('year'));

    // Expenses
    if (projects) {
      dispatch(fetchExpenses('token!!!', 
      employee, 
      year, 
      month + 1,
      projects));
    }
  }, [projects, dispatch, location.search]);

  
  const onChangeMonthHandler = (monthYear:any) => {
    let aux = monthYear.split("-");
    let month = parseInt(aux[0]);
    let year = parseInt(aux[1]);
    dispatchEmployee({ type: 'USER_CHANGE', employee: userState.employee, name: userState.name, year, month });
    dispatch(expensesInit(userState.employee, year, month + 1));
    // Refresh projects
    sendRequest({employee: userState.employee, year, month: month + 1});
    // Expense types
    sendRequest2({employee: userState.employee, year, month: month + 1});
  }

  const onAddHandler = () => {
    dispatch(addExpense(part.year, part.month));
  };

  const onSaveHandler = () => {
    dispatch(putExpenses('token!!!', part, 'GE'));
  };

  const onSendHandler = () => {
    dispatch(putExpenses('token!!!', part, 'EE'));
  };

  const onCancelHandler = () => {
    dispatch(fetchExpenses('token!!!', part.employee, part.year, part.month + 1, projects));
  };
  
  const onPrintHandler = () => {
    const tablePrint = document.querySelector('table.responsive');
    localStorage.setItem('print', tablePrint?.innerHTML ??'');
    // data
    let data: any[] = [];
    part.expenses.forEach((item: Expense) => {
      const project = projects.find((proj:OptionType) => parseInt(proj.value) === item.project);
      const concept = concepts.find((conc:OptionType) => parseInt(conc.value) === item.expenseType);
      data.push(project?.label??'');
      data.push(concept?.label??'');
    });
    localStorage.setItem('printSelec', JSON.stringify(data)); 
    // employee
    localStorage.setItem('employee', JSON.stringify(userState));
    navigate(`/print`);
  };

  if (status === 'pending' || status2 === 'pending' || loading) {
    return (
      <div className='centered'>
        <LoadingSpinner />
      </div>
    );
  }
  return (
  <Fragment>

  <div className='mt-4'/>

  <SelectMonth title={t('Parte de Gastos Mensual')} 
    employee={part.employee} name={userState.name}
    monthYear={part.month + '-' + part.year}
    rol={rol}
    monthNotClosed={monthNotClosed}
    onChangeMonth={onChangeMonthHandler}
  ></SelectMonth>

<div className='container_'>
    <ExpensesList part={part} 
      projects={projects ? projects : []} 
      concepts={concepts ? concepts : []}/>

    <div className={cx({
                'InfoMessage': true,
                'msg': error || error1 || error2
              })} >
      {error || error1 || error2}
    </div>
  <Toolbar 
        modifiable={true}
        onAdd={onAddHandler}
        onSave={onSaveHandler}
        onSend={onSendHandler}
        onCancel={onCancelHandler}
        onPrint={onPrintHandler}
    ></Toolbar>
</div>

</Fragment>
  );
};

export default Expenses;
