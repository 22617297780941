import { useTranslation } from 'react-i18next';
import classes from './ExpensesList.module.scss';
import { ExpensesPart, Expense } from '../../types/Expense';
import ExpenseItem from './ExpenseItem';
import { OptionType } from '../../types/Props';


const ExpensesList: React.FC<{part: ExpensesPart;
          projects: OptionType[], concepts: any;
          children?: React.ReactNode}> = (props) => {
  
  const { t } = useTranslation();
            
  let localizedNumber = new Intl.NumberFormat('en-US', {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "symbol"
  }).format(props.part.total);

  let expenses: Expense[] = [];
  if (props.part.expenses) {
    expenses = props.part.expenses;
  }

  return (

      <table className={`${classes.grid_expenses} responsive`} cellSpacing="1" cellPadding={"3"}>
      <thead>
        <tr className={`${classes.fondoAzul} mv-grid-title-tr`}>
          <th scope="col">{t('Proyecto')}</th>
          <th scope="col">{t('Fecha')}</th>
          <th scope="col">{t('Hasta')}</th>
          <th scope="col">{t('Tipo')}</th>
          <th scope="col">{t('Unidades')}</th>
          <th scope="col">{t('Importe')}</th>
          <th scope="col">{t('Total')}</th>
          <th scope="col">{t('Descripcion')}</th>
          <th scope="col">{t('Estado')}</th>
        </tr>
      </thead>
      <tbody>

      {expenses.map((expense: any, index) => (
          <ExpenseItem
            key={expense.key}
            index={index}
            expense={expense}
            projects={props.projects} concepts={props.concepts}
          />
          ))}		  
			<tr className={classes.totalrow}>
			<td className="hide-xs"></td>
			<td className="hide-xs"></td>
			<td className="hide-xs"></td>
			<td className="hide-xs"></td>
			<td className="hide-xs"></td>
			<td className={`${classes.lblTotal} w30_xs text-end`}>{t('Total')}:</td>
			<td className={classes.total}>
        <input placeholder="0" size={9} maxLength={20}
              className={`${classes.mat_input_element}`}
              value={localizedNumber} readOnly={true} />
      </td>
			<td className="hide-xs"></td>
			<td className="hide-xs"></td>
			</tr>
      </tbody>
    </table>
)};

export default ExpensesList;
