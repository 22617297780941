// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingSpinner_spinner__\\+PytG {\n  display: inline-block;\n  width: 80px;\n  height: 80px;\n}\n.LoadingSpinner_spinner__\\+PytG:after {\n  content: ' ';\n  display: block;\n  width: 64px;\n  height: 64px;\n  margin: 8px;\n  border-radius: 50%;\n  border: 6px solid teal;\n  border-color: teal transparent teal transparent;\n  animation: LoadingSpinner_spinner__\\+PytG 1.2s linear infinite;\n}\n@keyframes LoadingSpinner_spinner__\\+PytG {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI/LoadingSpinner.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;AACd;AACA;EACE,YAAY;EACZ,cAAc;EACd,WAAW;EACX,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,sBAAsB;EACtB,+CAA+C;EAC/C,8DAAuC;AACzC;AACA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".spinner {\n  display: inline-block;\n  width: 80px;\n  height: 80px;\n}\n.spinner:after {\n  content: ' ';\n  display: block;\n  width: 64px;\n  height: 64px;\n  margin: 8px;\n  border-radius: 50%;\n  border: 6px solid teal;\n  border-color: teal transparent teal transparent;\n  animation: spinner 1.2s linear infinite;\n}\n@keyframes spinner {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "LoadingSpinner_spinner__+PytG"
};
export default ___CSS_LOADER_EXPORT___;
