// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h5 {\n  margin-bottom: 0 !important;\n}\n\n.FooterAxpe_social_networks__U0d7I {\n  display: flex;\n  flex-wrap: wrap;\n  margin: auto;\n  max-width: 8rem;\n}\n.FooterAxpe_social_networks__U0d7I a {\n  flex: 40% 1;\n  margin: 0.5rem 0;\n  padding: 0;\n}\n\n.FooterAxpe_axpe_footer__bJmWT {\n  display: none !important;\n  width: 100%;\n  border-top: 1px solid lightgray;\n  font-size: 12px;\n  padding-top: 1rem;\n}\n.FooterAxpe_axpe_footer__bJmWT a {\n  color: black;\n  text-decoration: none;\n}\n\n@media screen and (max-width: 991px) {\n  .FooterAxpe_axpe_footer__bJmWT {\n    display: none !important;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/layout/FooterAxpe.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,YAAA;EACA,eAAA;AACF;AAAE;EACI,WAAA;EACA,gBAAA;EACA,UAAA;AAEN;;AAEA;EACE,wBAAA;EACA,WAAA;EACA,+BAAA;EACA,eAAA;EACA,iBAAA;AACF;AAAE;EACE,YAAA;EACA,qBAAA;AAEJ;;AAEA;EAEE;IACE,wBAAA;EAAF;AACF","sourcesContent":["h5 {\n  margin-bottom: 0 !important;\n}\n\n.social_networks {\n  display: flex;\n  flex-wrap: wrap;\n  margin: auto;\n  max-width: 8rem;\n  a {\n      flex: 40%;\n      margin: 0.5rem 0;\n      padding: 0;\n  }\n}\n\n.axpe_footer {\n  display: none !important;\n  width: 100%;\n  border-top: 1px solid lightgray;\n  font-size: 12px;\n  padding-top: 1rem;\n  a {\n    color: black;\n    text-decoration: none;\n  }\n}\n\n@media screen \nand (max-width: 991px) {\n  .axpe_footer {\n    display: none !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"social_networks": "FooterAxpe_social_networks__U0d7I",
	"axpe_footer": "FooterAxpe_axpe_footer__bJmWT"
};
export default ___CSS_LOADER_EXPORT___;
