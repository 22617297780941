import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

import { expensesTotals } from '../../lib/Utils';
import { Expense, ExpensesPart } from '../../types/Expense';
import { AnyAction } from 'redux';

// Define a type for the slice state
interface ExpensesState {
    part: ExpensesPart,
    error: string | null,
    loading: boolean
  }

const initialState: ExpensesState = {
    part: new ExpensesPart(),
    loading: false,
    error: null
};

const statesPending = ['GE', 'RG', 'RF'];

const expensesInit = ( state: ExpensesState, action: AnyAction  ) => {
    return updateObject( state, 
        { loading: false, 
            error: null, 
            part: initExpenses(action.employee, action.month, action.year) } );
};

const putExpense = ( state: ExpensesState, action: AnyAction  ) => {
    state.part.expenses[action.index] = action.expense;
    expensesTotals(state.part);
    return updateObject( state, {
        part: state.part
    } );
};

const deleteExpense = ( state: ExpensesState, action: AnyAction  ) => {
    state.part.expenses.splice(action.index, 1);
    expensesTotals(state.part);
    return updateObject( state, {
        part: state.part
    } );
};

const addExpense = ( state: ExpensesState, action: AnyAction  ) => {
    let expense = newExpense();
    state.part.expenses = state.part.expenses.concat( expense);

    return updateObject( state, {
        part: state.part
    } );
};

const putExpensesStart = ( state: ExpensesState, action: AnyAction  ) => {
    return updateObject( state, { 
        loading: true,
        error: null
    } );
};

const putExpensesSuccess = ( state: ExpensesState, action: AnyAction  ) => {
    if (action.state === 'EE')  {
        state.part.expenses.forEach(item => {
            if (statesPending.includes(item.state!)) item.state = 'EE';
        });
    }
    return updateObject( state, {
        loading: false,
        error: action.msg,
        part: state.part
    } );
};

const putExpensesFail = ( state: ExpensesState, action: AnyAction  ) => {
    return updateObject( state, { 
        loading: false,
        error: action.error
    } );
};

const fetchExpensesStart = ( state: ExpensesState, action: AnyAction  ) => {
    return updateObject( state, 
        { loading: true, 
            error: null, 
            part: initExpenses(action.employee, action.month, action.year) } );
};

const fetchExpensesSuccess = ( state: ExpensesState, action: AnyAction  ) => {
    if (action.part.expenses.length === 0) {
        // empty list
        return updateObject( state, {
            loading: false,
            error: null
        } );
    }
    return updateObject( state, {
        part: action.part,
        loading: false,
        error: null
    } );
};

const fetchExpensesFail = ( state: ExpensesState, action: AnyAction  ) => {
    return updateObject( state, { 
        loading: false,
        error: action.error
    } );
};

const reducer = ( state = initialState, action: AnyAction  ) => {
    switch ( action.type ) {
        case actionTypes.EXPENSES_INIT: return expensesInit( state, action );
        case actionTypes.PUT_EXPENSES_START: return putExpensesStart( state, action );
        case actionTypes.PUT_EXPENSES_SUCCESS: return putExpensesSuccess( state, action )
        case actionTypes.PUT_EXPENSES_FAIL: return putExpensesFail( state, action );
        case actionTypes.FETCH_EXPENSES_START: return fetchExpensesStart( state, action );
        case actionTypes.FETCH_EXPENSES_SUCCESS: return fetchExpensesSuccess( state, action );
        case actionTypes.FETCH_EXPENSES_FAIL: return fetchExpensesFail( state, action );
        case actionTypes.ADD_EXPENSE: return addExpense( state, action );
        case actionTypes.PUT_EXPENSE: return putExpense( state, action );
        case actionTypes.DELETE_EXPENSE: return deleteExpense( state, action );
        default: return state;
    }
};

export default reducer;


const initExpenses = (employee: number, year: number, month: number) => {
    let ret;
    ret = new ExpensesPart();
    ret.employee = employee;
    ret.month = month;
    ret.year = year;
  
    // Una actividad vacía por defecto
    let gasto = newExpense();
  
    ret.total = 0;
  
    ret.expenses.push(gasto); 
    return ret;
  }

  const newExpense = () => {
    let expense = new Expense();
  
    expense.state = 'GE';

    expense.dateRange = [null,null];

    expense.nmUnits = 0;
    expense.nmAmount = 0;
    expense.nmTotal = 0;
    
    return expense;
  }
