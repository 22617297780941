// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DayDialog_mat-input__FPHOq {\n  font: inherit;\n  background: transparent;\n  color: currentColor;\n  padding: 0;\n  margin: 0;\n  width: 50%;\n  max-width: 50%;\n  vertical-align: bottom;\n  text-align: inherit;\n}", "",{"version":3,"sources":["webpack://./src/components/activ/DayDialog.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;EACA,cAAA;EACA,sBAAA;EACA,mBAAA;AACJ","sourcesContent":[".mat-input {\n    font: inherit;\n    background: transparent;\n    color: currentColor;\n    padding: 0;\n    margin: 0;\n    width: 50%;\n    max-width: 50%;\n    vertical-align: bottom;\n    text-align: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mat-input": "DayDialog_mat-input__FPHOq"
};
export default ___CSS_LOADER_EXPORT___;
