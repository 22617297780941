
export class OptionType {
  constructor(value?: string, label?: string) {
    this.value = value ? value : '';
    this.label = label ? label : '';
   }


    value!: string;
    label!: string;
  };

export interface ResponseGenerator{
    config?:any,
    data?:any,
    headers?:any,
    request?:any,
    status?:number,
    statusText?:string,
    length?:number,
    return?:any
}