import { NavigateFunction } from "react-router-dom";
import * as actionTypes from "./actionTypes";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token: string | null, userId: string, rol: number, 
    enterprise: number | null, dsEnterprise: string | null, monthNotClosed: number | null = null) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId,
    rol,
    enterprise,
    dsEnterprise,
    monthNotClosed
  };
};

export const authFail = (error: string) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  // localStorage.removeItem('token');
  // localStorage.removeItem('expirationDate');
  // localStorage.removeItem('userId');
  return {
    type: actionTypes.AUTH_INITIATE_LOGOUT
  };
};

export const logoutSucceed = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = (expirationTime: number) => {
  return {
    type: actionTypes.AUTH_CHECK_TIMEOUT,
    expirationTime: expirationTime
  };
};

export const auth = (user: string | undefined, password: string | undefined, navigate: NavigateFunction) => {
  return {
    type: actionTypes.AUTH_USER,
    user,
    password,
    navigate
  };
};

export const checkEnterprise = (user: string | undefined, password: string | undefined, enterprise: number, navigate: NavigateFunction) => {
  return {
    type: actionTypes.CHECK_ENTERPRISE,
    user,
    password,
    enterprise,
    navigate
  };
};

export const setAuthRedirectPath = (path: string) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const authCheckState = () => {
  return {
    type: actionTypes.AUTH_CHECK_STATE
  };
};

export const authChangePassword = (user: string, rol: number, password: string, enterprise: string | null) => {
  return {
    type: actionTypes.AUTH_CHANGE_PASSWORD,
    user,
    rol,
    password,
    enterprise
  };
};

export const setEnterprises = (enterprises: any) => {
  return {
    type: actionTypes.SET_ENTERPRISES,
    enterprises
  };
};


