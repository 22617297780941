import { Fragment, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '..';
import classes from './Print.module.scss';
import classNames from "classnames/bind";
import { useLocation, useNavigate } from 'react-router-dom';
import { employeeReducer, initEmployee } from '../lib/Utils';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(classes);

const Home: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { logo} = useSelector((state: RootState) => state.auth);
    const [userState, dispatchEmployee] = useReducer(employeeReducer, initEmployee);
    const [title, setTitle] = useState('');

    useEffect(() => {
        const tableHtml = localStorage.getItem('print');
        setTitle(tableHtml?.indexOf('ActivitiesList') != -1 ? t('Actividades') : t('Nota de gastos'));
        const tableDom = document.querySelector('.printable');
        if (tableDom) {
            tableDom.innerHTML = tableHtml || '<table ></table>';

            const data = localStorage.getItem('printSelec');  
            const dataArr: any[] = JSON.parse(data??'');
            dataArr.forEach(item => {
                let select = document.getElementsByTagName('select')[0];
                select.replaceWith(item);
            });

            removeElements('table.printable td.mv-grid-title-td-hide');
            removeElements('table.printable tr td:last-child');
        }
        const userStateAux = localStorage.getItem('employee');
        if (userStateAux) {
            const userStateData = JSON.parse(userStateAux);  
            dispatchEmployee({ type: 'USER_CHANGE', 
                employee: userStateData.employee, 
                name: userStateData.name,
                year: userStateData.year, 
                month: userStateData.month});
        }
        window.scrollTo(0, 0);
      }, []);

    const removeElements = (query: string) => {
        const tdsHide = document.querySelectorAll(query);
        for(let i = 0;i < tdsHide.length; i++)
        {
            tdsHide[i].parentNode?.removeChild(tdsHide[i]);
        }
    };

    const onPrint = () => {
      window.print();
    };

    const onCancel = () => {
      navigate(-1);
    };


  return (
    <Fragment>
            
<div className={classes.contenedor_formulario}>
    <table className={classes.filter_table}>
        <tr>
            <td width="45%" valign={'top'} >
                <table width="100%">
                    <tr>                                       
                        <td align="center" colSpan={2} >
                            < img src={logo} alt={t('Axpe Consulting')}  style={{
                                width: '40rem',
                                margin: '1rem'
                        }}/></td>
                    </tr>
                    <tr>
                        <td align="center" colSpan={2}>
                            <h1 className={classes.letter}>
                                <span id="ctl00_phContenidoRight_lbActividades"> { title } </span>
                            </h1>
                        </td>
                    </tr>
                    <tr>
                        <td  colSpan={2} >&nbsp;</td>
                    </tr>
                    <tr>
                        <td className={classes.literal} width={'30%'} style={{width:'30%'}}>
                            <span id="ctl00_phContenidoRight_lbNombreYApellidos">{t('Nombre y Apellidos')} ***</span>
                        </td>
                        <td className={classes.value} width={'70%'}  style={{width:'70%'}}>
                            { userState.name }
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.literal}>
                            <span id="ctl00_phContenidoRight_lbMesAnio">{t('Mes/Año')}:</span>
                        </td>
                        <td className={classes.value} >
                        { userState.month + 1 } /  {userState.year }
                        </td>
                    </tr>        
                </table>
            </td>
            <td width="55%" valign={'top'}>
            <table cellPadding={0} cellSpacing={0} border={1} 
                      className={`${classes.sign_table} mt-3`}>
                <tr>
                    <td className={classes.literal} width={'110px'}>
                        <span id="ctl00_phContenidoRight_lbFirmaCorresponsal">{t('Firma Corresponsal')}:</span>
                    </td>
                    <td width="400px" height="50px"><br /> </td>
                </tr>
                <tr>
                    <td className={classes.literal} width={'50px'}>
                        <span id="ctl00_phContenidoRight_lbFirmaGerente">{t('Firma Gerente')}:</span>
                    </td>
                    <td width="400px" height="50px"><br /> </td>
                </tr>
                <tr>
                    <td className={classes.literal}>
                        <span id="ctl00_phContenidoRight_lbVB">{t('Vº Bº')}:</span>
                    </td>
                    <td width="400px" height="50px"><br /> </td>
                </tr>
            </table>
          </td>
        </tr>
    </table>
    <br />
    <div id="ctl00_phContenidoRight_UpdatePanel1">
	                                                                                    	            
        <table className={`${classes.form_table} printable`} style= {{borderCollapse: 'collapse'}}>
...
        </table>           
        
</div>
          
    <div id="ctl00_phContenidoRight_pnlBoton" style={{height:'50px',width:'125px'}} className='mt-2 d-flex'>
	
        <button onClick={onPrint}  
            className={cx({
            'btn btn-labeled btn-secondary text-white d-flex align-items-center me-2': true
            })}>
            <span className={cx({
                'btn-labeled': true,
                'margin_right_small': true
                })}><i className="fa fa-remove"></i></span>{t('Imprimir')}
        </button> 

        <button onClick={onCancel}  
            className={cx({
              'btn btn-labeled btn-danger d-flex align-items-center': true,
              'margin_right': true
            })}>
              <span className={cx({
                  'btn-labeled': true,
                  'margin_right_small': true
                })}><i className="fa fa-remove"></i></span>{t('Cancelar')}
        </button>
    
</div>
</div>
          
<span id="ctl00_phContenidoRight_lbImprimaHorizontalOApaisado">(1) {t('Imprima en horizontal o apaisado.')}</span>    


    </Fragment>
  );
};

export default Home;
