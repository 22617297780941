import { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import classes from "./HolidayList.module.scss";
import classNames from "classnames/bind";
import { HolidaysPart, MonthHolidays } from "../../types/Holiday";
import { useDispatch } from "react-redux";
import { checkHoliday } from "../../store/actions/holidays";

const cx = classNames.bind(classes);

const HolidayList: React.FC<{
  part: HolidaysPart;
  children?: React.ReactNode;
}> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedMonth, setSelectedMonth] = useState(new Date().getUTCMonth());

  let days = Array.from(Array(31).keys());

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const inputHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    indexMonth: number,
    indexDia: number
  ) => {
    //props.part.holidays[indexMonth].days[indexDia].check = event.target.value ? true : false;
    if (
      event.target.checked &&
      props.part.inProcessDays >= props.part.pendingDays
    ) {
      alert(t("Agotados los dias"));
      return;
    }
    dispatch(checkHoliday(indexMonth, indexDia, event.target.checked));
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(Number(event.target.value));
  };

  let holidays: MonthHolidays[] = [];
  if (props.part.holidays) {
    holidays = props.part.holidays;
  }

  return (
    <Fragment>
      <div className={classes.form_control}>
        <select
          onChange={handleMonthChange}
          value={selectedMonth}
          className={`${classes.clsSelec} d-block d-lg-none`}
          title="Select month"
        >
          {holidays.map((holidaysMonth: MonthHolidays, indexMonth: number) => (
            <option key={indexMonth} value={indexMonth}>
              {holidaysMonth.month}
            </option>
          ))}
        </select>
      </div>

      <table
        className={`${classes.grid_holidays} responsive table-info table-striped`}
        cellSpacing="0"
        cellPadding={"1"}
      >
        <thead>
          <tr className={`${classes.fondoAzul} mv-grid-title-tr`}>
            <th className={`${classes.monthLabel} mv-grid-title-td-show`}>
            {t('Mes')}
            </th>
            {days.map((dia: any, index) => (
              <th key={index}>{dia + 1}</th>
            ))}
            <th>{t('Total')}</th>
          </tr>
        </thead>
        <tbody className={classes.tableBody}>
          {holidays.map((holidaysMonth: MonthHolidays, indexMonth) => (
            <Fragment key={indexMonth}>
              {(!isMobile || selectedMonth === indexMonth) && (
                <tr key={indexMonth}>
                  <td className={`${classes.monthLabel} mv-grid-title-td-show`}>
                    {/* cambio */}
                    {/* { holidaysMonth.month  } */}
                    <span className={classes.elipsis}>
                      {holidaysMonth.month}
                    </span>
                    {/* fin cambio */}
                  </td>
                  {days.map((indexDia) => (
                    <Fragment key={indexDia}>
                      <td className="mv-grid-title-td-hide ">
                        D&iacute;a&nbsp;{indexDia + 1}
                      </td>
                      {/* 
          <td> {JSON.stringify(holidaysMonth.days[indexDia], null, 2) } </td>
           */}
                      {holidaysMonth.days[indexDia] && (
                        <td
                          className={cx({
                            blue: true,
                            "text-center": true,
                            bgYellow: holidaysMonth.days[indexDia].weekend,
                            festive: holidaysMonth.days[indexDia].festive && !holidaysMonth.days[indexDia].check,
                            draft: holidaysMonth.days[indexDia].state === "GE",
                            sent: holidaysMonth.days[indexDia].state === "EE",
                            visado:
                              holidaysMonth.days[indexDia].state === "VG" ||
                              holidaysMonth.days[indexDia].state === "VF",
                            refused:
                              holidaysMonth.days[indexDia].state === "RG" ||
                              holidaysMonth.days[indexDia].state === "RF",
                            enjoyed:
                              holidaysMonth.days[indexDia].state === "OK",
                            /*
                  'no-edit-allowed blur': holidaysMonth.disabled || !holidaysMonth.days[indexDia] || (holidaysMonth.days[indexDia].state 
                          &&  holidaysMonth.days[indexDia].state !== 'GE' && holidaysMonth.days[indexDia].state !== 'RG')
                  */
                          })}
                        >
                          {holidaysMonth.days[indexDia].weekend && (
                              <span
                                className={
                                    classes.weekenOrDraf
                                }
                              >
                                <i
                                    className="fa fa-minus"
                                    aria-hidden="true"
                                ></i>
                              </span>
                          )}
                          {!holidaysMonth.days[indexDia].weekend && (
                            <input
                              type="checkbox"
                              step="1"
                              size={2}
                              maxLength={2}
                              min={0}
                              max={24}
                              placeholder="0"
                              checked={holidaysMonth.days[indexDia].check}
                              onChange={(e) =>
                                inputHandler(e, indexMonth, indexDia)
                              }
                              className={cx({
                                "mat-input-element": true,
                                "disable-check":
                                  holidaysMonth.disabled ||
                                  !holidaysMonth.days[indexDia] ||
                                  (holidaysMonth.days[indexDia].state &&
                                    holidaysMonth.days[indexDia].state !==
                                      "GE" &&
                                    holidaysMonth.days[indexDia].state !==
                                      "RG"),
                                "no-edit-allowed blur":
                                  holidaysMonth.disabled ||
                                  !holidaysMonth.days[indexDia] ||
                                  (holidaysMonth.days[indexDia].state &&
                                    holidaysMonth.days[indexDia].state !==
                                      "GE" &&
                                    holidaysMonth.days[indexDia].state !==
                                      "RG"),
                              })}
                            />
                          )}
                        </td>
                      )}
                      {!holidaysMonth.days[indexDia] && <td></td>}
                    </Fragment>
                  ))}
                  <td className="mv-grid-title-td-hide "></td>
                  <td className="widthColBtn">{holidaysMonth.total}</td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default HolidayList;
