import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { AnyAction } from 'redux';


// Define a type for the slice state
interface AuthState {
    token: string | null,
    userId: string | null,
    rol: number | null,
    error: string | null,
    loading: boolean,
    authRedirectPath: string,
    enterprises: any[],
    dsEnterprise: string | null,
    enterprise: number | null,
    logo: string | null,
    urlEnterprise: string | null,
    monthNotClosed: number | null
  }

const initialState: AuthState = {
    token: null,
    userId: null,
    rol: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    enterprises: [],
    enterprise: null,
    dsEnterprise: null,
    logo: null,
    urlEnterprise: null,
    monthNotClosed: null
};

const authStart = ( state: AuthState, action: AnyAction  ) => {
    return updateObject( state, { error: null, loading: true, 
        enterprises: [] } );
};

const authSuccess = (state: AuthState, action: AnyAction ) => {
    return updateObject( state, { 
        token: action.idToken,
        userId: action.userId,
        rol: action.rol,
        enterprise: action.enterprise,
        dsEnterprise: action.dsEnterprise,
        logo: getLogo(action.enterprise),
        urlEnterprise: getUrl(action.enterprise),
        error: null,
        loading: false,
        monthNotClosed: action.monthNotClosed ? action.monthNotClosed : state.monthNotClosed
     } );
};

const authFail = (state: AuthState, action: AnyAction ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const authLogout = (state: AuthState, action: AnyAction ) => {
    return updateObject(state, { token: null, userId: null, 
        enterprises: [], enterprise: null, logo: null });
};

const setAuthRedirectPath = (state: AuthState, action: AnyAction ) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const setEnterprises = (state: AuthState, action: AnyAction ) => {
    let enterprises = action.enterprises;
    return updateObject(state, { enterprises })
}

const reducer = ( state = initialState, action: AnyAction  ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        case actionTypes.SET_ENTERPRISES: return setEnterprises(state,action);
        default:
            return state;
    }
};

const getLogo = (enterprise: number | null) => {
    if (!enterprise) {
        return null;
    }
    switch(enterprise) {
    case 1:
        return "cabecera_logo.gif";
    case 12:
        return "cabecera_logo_TeamPRO.gif";
    case 15:
        return "cabecera_logo15.png";
    case 17:
        return "cabecera_logo_QUANT.gif";
    case 20:
        return "cabecera_logo_QUANT_AI.gif";
    case 24:
        return "cabecera_logo_USA.png";
    default:
        return `cabecera_logo${enterprise}.gif`;
    }
}

const getUrl = (enterprise: number | null) => {
    if (!enterprise) {
        return null;
    }

    switch(enterprise) {
    case 1:
        return "https://controlhorario.axpe.com";
    case 2:
        return "https://controlhorario.generalsw.com";
    case 17:
        return "https://controlhorario.iquant.tech";
    case 20:
        return "https://controlhorario.iquant.tech";
    case 22:
        return "https://controlhorario.arktic.es";
    case 23:
        return "https://controlhorario.hamon.es";       
    default:
        return `https://controlhorario.axpe.com`;
    }
}

export default reducer;