// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Card_card__9h8Dh {\n  max-width: 30rem;\n  border: 1px solid #10182a;\n  box-shadow: none;\n\n  padding: 1rem;\n  margin: 2rem 1rem;\n  border-radius: 6px;\n  background-color: white;\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI/Card.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,gBAAgB;;EAEhB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;AACzB","sourcesContent":[".card {\n  max-width: 30rem;\n  border: 1px solid #10182a;\n  box-shadow: none;\n\n  padding: 1rem;\n  margin: 2rem 1rem;\n  border-radius: 6px;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Card_card__9h8Dh"
};
export default ___CSS_LOADER_EXPORT___;
