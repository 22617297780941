import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-datepicker/dist/react-datepicker.css";
import App from './App';

import { Provider } from "react-redux";
import { combineReducers } from "redux";
import { configureStore } from '@reduxjs/toolkit';
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";

import authReducer from "./store/reducers/auth";
import activReducer from "./store/reducers/activities";
import expenseReducer from "./store/reducers/expenses";
import holidaysReducer from "./store/reducers/holidays";
import { watchAuth, watchActivities, watchExpenses, watchHolidays } from "./store/sagas";
import { StyledEngineProvider } from '@mui/material/styles';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const rootReducer = combineReducers({
  auth: authReducer,
  activ: activReducer,
  expense: expenseReducer,
  holidays: holidaysReducer
});

export type RootState = ReturnType<typeof rootReducer>;

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat(thunk).concat(sagaMiddleware)

})

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchActivities);
sagaMiddleware.run(watchExpenses);
sagaMiddleware.run(watchHolidays);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <Provider store={store}>
  <BrowserRouter>
  <StyledEngineProvider injectFirst>
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
  </StyledEngineProvider>
  </BrowserRouter>
  </Provider>
);
