export const PUT_ACTIVITIES = 'PUT_ACTIVITIES';
export const PUT_ACTIVITIES_START = 'PUT_ACTIVITIES_START';
export const PUT_ACTIVITIES_SUCCESS = 'PUT_ACTIVITIES_SUCCESS';
export const PUT_HOLIDAY_SUCCESS = 'PUT_HOLIDAY_SUCCESS';
export const PUT_ACTIVITIES_FAIL = 'PUT_ACTIVITIES_FAIL';
export const ACTIVITIES_INIT = 'PURCHASE_INIT';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const PUT_ACTIVITY = 'PUT_ACTIVITY';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const PUT_HOLIDAY = 'PUT_HOLIDAY';

export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const FETCH_ACTIVITIES_START = 'FETCH_ACTIVITIES_START';
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export const FETCH_ACTIVITIES_FAIL = 'FETCH_ACTIVITIES_FAIL';

export const PUT_EXPENSES = 'PUT_EXPENSES';
export const PUT_EXPENSES_START = 'PUT_EXPENSES_START';
export const PUT_EXPENSES_SUCCESS = 'PUT_EXPENSES_SUCCESS';
export const PUT_EXPENSES_FAIL = 'PUT_EXPENSES_FAIL';
export const EXPENSES_INIT = 'EXPENSES_INIT';
export const ADD_EXPENSE = 'ADD_EXPENSE';
export const PUT_EXPENSE = 'PUT_EXPENSE';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';

export const FETCH_EXPENSES = 'FETCH_EXPENSES';
export const FETCH_EXPENSES_START = 'FETCH_EXPENSES_START';
export const FETCH_EXPENSES_SUCCESS = 'FETCH_EXPENSES_SUCCESS';
export const FETCH_EXPENSES_FAIL = 'FETCH_EXPENSES_FAIL';


export const PUT_HOLIDAYS = 'PUT_HOLIDAYS';
export const PUT_HOLIDAYS_START = 'PUT_HOLIDAYS_START';
export const PUT_HOLIDAYS_SUCCESS = 'PUT_HOLIDAYS_SUCCESS';
export const PUT_HOLIDAYS_FAIL = 'PUT_HOLIDAYS_FAIL';
export const HOLIDAYS_INIT = 'HOLIDAYS_INIT';
export const CHECK_HOLIDAY = 'CHECK_HOLIDAY';
export const FETCH_HOLIDAYS = 'FETCH_HOLIDAYS';
export const FETCH_HOLIDAYS_START = 'FETCH_HOLIDAYS_START';
export const FETCH_HOLIDAYS_SUCCESS = 'FETCH_HOLIDAYS_SUCCESS';
export const FETCH_HOLIDAYS_FAIL = 'FETCH_HOLIDAYS_FAIL';

export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const SET_ENTERPRISES = 'SET_ENTERPRISES';
export const CHECK_ENTERPRISE = 'CHECK_ENTERPRISE';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';