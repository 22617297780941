import { Expense, ExpensesPart } from "../../types/Expense";
import { OptionType } from "../../types/Props";
import * as actionTypes from "./actionTypes";

export const putExpensesSuccess = (state: string, msg: string) => {
  return {
    type: actionTypes.PUT_EXPENSES_SUCCESS,
    state,
    msg
  };
};

export const putExpensesFail = (error: string) => {
  return {
    type: actionTypes.PUT_EXPENSES_FAIL,
    error
  };
};

export const putExpensesStart = () => {
  return {
    type: actionTypes.PUT_EXPENSES_START
  };
};

export const putExpenses = (token: string, part: ExpensesPart, state: string) => {
  return {
    type: actionTypes.PUT_EXPENSES,
    part,
    state,
    token
  };
};

export const expensesInit = (employee: number, year: number, month: number) => {
  return {
    type: actionTypes.EXPENSES_INIT,
    employee,
    year,
    month
  };
};

export const fetchExpensesSuccess = (part: ExpensesPart) => {
  return {
    type: actionTypes.FETCH_EXPENSES_SUCCESS,
    part
  };
};

export const fetchExpensesFail = (error: string) => {
  return {
    type: actionTypes.FETCH_EXPENSES_FAIL,
    error
  };
};

export const fetchExpensesStart = (employee: number, year: number, month: number) => {
  return {
    type: actionTypes.FETCH_EXPENSES_START,
    employee,
    year,
    month
  };
};

export const fetchExpenses = (token: string, employee: number, year: number, month: number, projects: OptionType[]) => {
  return {
    type: actionTypes.FETCH_EXPENSES,
    token,
    projects,
    employee,
    year,
    month
  };
};

export const addExpense = (year: number, month: number) => {
  return {
    type: actionTypes.ADD_EXPENSE,
    year,
    month
  };
};

export const putExpense = (index: number, expense: Expense) => {
  return {
    type: actionTypes.PUT_EXPENSE,
    index,
    expense
  };
};

export const deleteExpense = (index: number) => {
  return {
    type: actionTypes.DELETE_EXPENSE,
    index
  };
};

