import { Expense, ExpensesPart } from "../types/Expense";
import { HolidaysPart } from "../types/Holiday";
import { OptionType } from "../types/Props";


export const MOCK = false;

export const  LOGIN_MOCK = {nombrecompleto: 'CARLOS QUESADA GALAN', 
  empleado: 176, cdrol: 1, cdgerente: null, cdunidad: 2, token: 'token!!!', empresas: 1};


export const PROYECTOS_MOCK: OptionType[] = [
    {
      value: "2981",
      label: "SE C2867 - Enfermedad - (Cantabria)"
    },
    {
      value: "2982",
      label: "SE C2868 - Vacaciones - (Cantabria)"
    },
    {
      value: "2983",
      label: "SE C2869 - Paternidad / Maternidad - (Cantabria)"
    },
    {
      value: "2984",
      label: "SE C2870 - Permiso No Retribuido - (Cantabria)"
    },
    {
      value: "2985",
      label: "SE C2871 - Permiso Retribuido - (Cantabria)"
    },
    {
      value: "2986",
      label: "SE C2872 - Visita médica - (Cantabria)"
    },
    {
      value: "3199",
      label: "GO G3085 - Servicio PaP 2019-2021 - (Administraciones Publicas)"
    },
    {
      value: "3433",
      label: "TEKP3292 - SUMA Gobierno de Cantabria - (Cantabria)"
    }
  ];

  export const TIPO_GASTO: OptionType[] = [
    {
      value: "1",
      label: 'Transporte'
    },
    {
      value: "2",
      label: 'Comida'
    },
    {
      value: "3",
      label: 'Alojamiento'
    },
    {
      value: "4",
      label: 'Taxi'
    },
    {
      value: "11",
      label: 'Desayuno'
    },
    {
      value: "12",
      label: 'Aparcamiento'
    },
    {
      value: "13",
      label: 'Teléfono'
    },
    {
      value: "14",
      label: 'Pequeñas compras'
    },
    {
      value: "16",
      label: 'Anticipo'
    },
    {
      value: "17",
      label: 'Guardias, ...'
    },
    {
      value: "18",
      label: 'Bonus'
    },
    {
      value: "19",
      label: 'Dietas'
    },
    {
      value: "20",
      label: 'ESCUENTO ANTICIPO'
    }
    
  ];

  
  export const EMPLEADOS: OptionType[] = [
    {
      value: "176",
      label: 'Carlos Quesada Galán'
    }
    
  ];

  export const GASTO_MOCK: Expense = {
    project: 3433,
    expenseType: 1,
    dateRange: [new Date(), new Date()],
    nmUnits: 1,
    nmAmount: 1,
    nmTotal: 1,
    dsDescription: '',
    state: 'GE',
    key: "id" + Math.random().toString(16).slice(2)
  };

  export const GASTOS_MOCK: ExpensesPart = {
    employee: 176,
    month: 10,
    year: 2022,
    expenses: [GASTO_MOCK],

    total: 1,
    modifiable: true
  };

  
  export const HOLIDAYS_MOCK: HolidaysPart = {  
    employee: 176,
    year: 2022,
    prevYear: 2021,
    holidays: [],

    totalDays: 0,
    pendingDays: 0,
    enjoyedPrevYear: 0,
    inProcessDays: 0,
    enjoyed: 0,
    enjoyedYear: 0,

    manager: 'Juan Manuel Martinez Ortiz',

    stateIni: ''
  };
