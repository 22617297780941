// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_main__FxQfo {\n  margin: auto;\n  width: 95%;\n  max-width: 200rem;\n}\n\n/* fill empty space to move footer to bottom */\n.Layout_stuff__jSht- {\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/layout/Layout.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,UAAU;EACV,iBAAiB;AACnB;;AAEA,8CAA8C;AAC9C;EACE,YAAY;AACd","sourcesContent":[".main {\n  margin: auto;\n  width: 95%;\n  max-width: 200rem;\n}\n\n/* fill empty space to move footer to bottom */\n.stuff {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "Layout_main__FxQfo",
	"stuff": "Layout_stuff__jSht-"
};
export default ___CSS_LOADER_EXPORT___;
