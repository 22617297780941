import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const BasicTextField: React.FC<{title?: string; id?: string; value?: string; width?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  children?: React.ReactNode}> = (props) => {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: props.width || '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id={props.id} label={props.title} variant="standard" 
          value={props.value} onChange={props.onChange}/>
    </Box>
  );
}

export default BasicTextField;