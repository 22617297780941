// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_home__kY\\+gR {\n  margin: 0;\n  display: flex;\n  justify-content: space-between;\n}\n\n@media screen and (max-width: 767px) {\n  .Home_home__kY\\+gR {\n    flex-direction: column;\n    justify-content: flex-start;\n  }\n  .Home_home__kY\\+gR button {\n    width: 10rem;\n  }\n}\n.Home_bloque__ROjfy {\n  LIST-STYLE-TYPE: decima;\n  font-size: 14px;\n}\n\n.Home_activities__NXXXa {\n  width: 50%;\n  float: left;\n  padding-right: 1rem;\n  padding-left: 1rem;\n}\n\n.Home_expenses__vM4Jp {\n  width: 49%;\n  float: left;\n}\n\n.Home_holidays__IItzh {\n  clear: both;\n  padding-right: 1rem;\n  padding-left: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/pages/Home.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,aAAA;EACA,8BAAA;AACF;;AAEA;EAEE;IACE,sBAAA;IACA,2BAAA;EAAF;EACE;IACE,YAAA;EACJ;AACF;AAGA;EACE,uBAAA;EACA,eAAA;AADF;;AAIA;EACE,UAAA;EAAY,WAAA;EACZ,mBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,UAAA;EAAY,WAAA;AACd;;AAEA;EACE,WAAA;EACA,mBAAA;EACA,kBAAA;AACF","sourcesContent":[".home {\n  margin: 0;\n  display: flex;\n  justify-content: space-between;\n}\n\n@media screen \nand (max-width: 767px) {\n  .home {\n    flex-direction: column;\n    justify-content: flex-start;\n    button {\n      width: 10rem;\n    }\n  }\n}\n\n.bloque {\n  LIST-STYLE-TYPE: decima; \n  font-size: 14px;\n}\n\n.activities {\n  width: 50%; float: left; \n  padding-right: 1rem;\n  padding-left: 1rem;\n}\n\n.expenses {\n  width: 49%; float: left\n}\n\n.holidays {\n  clear: both;\n  padding-right: 1rem;\n  padding-left: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": "Home_home__kY+gR",
	"bloque": "Home_bloque__ROjfy",
	"activities": "Home_activities__NXXXa",
	"expenses": "Home_expenses__vM4Jp",
	"holidays": "Home_holidays__IItzh"
};
export default ___CSS_LOADER_EXPORT___;
