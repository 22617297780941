import { OptionType } from "../types/Props";
import { MOCK, PROYECTOS_MOCK, TIPO_GASTO, EMPLEADOS  } from "./mock";
import BASE_URL from '../Config';
import axios from "../store/axios-sipac";
import { AxiosResponse } from "axios";
import { getError } from "./Utils";


export async function getProjects(requestData: any) {
  if (MOCK) {
    return PROYECTOS_MOCK;
  }

  const url = `${BASE_URL}employee/projects/${requestData.employee}/${requestData.month}/${requestData.year}`;
  const response: any = await fetch(url);

  if (!response.ok) {
    throw new Error(response.message || 'Error obteniendo proyectos.');
  }
  
  const data = await response.json();

  const transformeData = [];

  for (const i in data) {
    let aux = new OptionType();
    aux.value = data[i]['cdproyecto'];
    aux.label = data[i]['codproy_axpe'];

    transformeData.push(aux);
  }

  return transformeData;
}

export async function getConcepts(requestData: any) {
  const url = `${BASE_URL}employee/concepts/${requestData.employee}/${requestData.month}/${requestData.year}/${requestData.project}`;
  const response: any = await fetch(url);

  if (!response.ok) {
    throw new Error(response.message || 'Error obteniendo conceptos.');
  }
  
  const data = await response.json();

  const transformeData = [];
  const ret: any = {};

  for (const i in data) {
    let aux = new OptionType();
    aux.value = data[i]['cdconcepto'];
    aux.label = data[i]['dsconcepto'];

    transformeData.push(aux);
  }
  ret[requestData.project] = transformeData;

  return ret;
}

export async function getExpenseTypes(requestData: any) {
  if (MOCK) {
    return TIPO_GASTO;
  }
  const url = `${BASE_URL}employee/expenses/types`;
  const response: any = await fetch(url);

  if (!response.ok) {
    throw new Error(response.message || 'Error obteniendo tipos de gastos.');
  }
  
  const data = await response.json();

  const transformeData = [];

  for (const i in data) {
    let aux = new OptionType();
    aux.value = data[i]['cdtipo_gasto'];
    aux.label = data[i]['dstipo_gasto'];

    transformeData.push(aux);
  }

  return transformeData;
}

export async function getEmployees(requestData: any) {
  if (MOCK) {
    return EMPLEADOS;
  }
  const path = (requestData.rol === 4 ? `enterprise/employees/${requestData.enterprise}` : `employee/${requestData.manager}`);
  const url = `${BASE_URL}${path}/${requestData.month}/${requestData.year}`;

  const response: any = await fetch(url);

  if (!response.ok) {
    throw new Error(response.message || 'Error obteniendo emploeados.');
  }
  
  const data = await response.json();

  const transformeData = [];

  for (const i in data) {
    let aux = new OptionType();
    aux.value = data[i]['cdempleado'] + '@' + data[i]['nombrecompleto'];
    aux.label = data[i]['nombrecompleto'];

    transformeData.push(aux);
  }

  return transformeData;
}

export async function notClosedMonth(requestData: any) {
  const url = `${BASE_URL}not-closed-month`;

  const response: any = await fetch(url);

  if (!response.ok) {
    throw new Error(response.message || 'Error obteniendo mes no cerrado.');
  }
  
  const data = await response.json();

  return data['return'];
}

export async function yearHolidays(requestData: any) {
  const url = `${BASE_URL}festiveDays/${requestData.employee}/${requestData.year}`;

  try {
    const response: AxiosResponse<any> = await axios.get(url);
    return response.data;
  } catch (error) {
    throw new Error(getError(error) || 'Error obteniendo festivos.');
  }

}