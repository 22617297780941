import { useSelector } from 'react-redux';
import classNames from './FooterAxpe.module.scss';
import { RootState } from '../..';

const FooterAxpe: React.FC = () => {
  const {dsEnterprise} = useSelector((state: RootState) => state.auth);
  return (
    
<footer className={classNames.axpe_footer}>
<div className="container text-center text-md-left">
  <div className="row">
    <div className="col-md-4 col-lg-3 mr-auto mt-md-2 my-0 mt-4 mb-1">
      <h5 className="font-weight-bold mb-2">{ dsEnterprise }</h5>
      <p>Somos una multinacional independiente de consultoría, integración de sistemas y externalización de servicios que guía a grandes organizaciones en su camino hacia la excelencia.</p>
    </div>
    <hr className="clearfix w-100 d-md-none"/>
    <div className="col-md-2 col-lg-2 mx-auto mt-md-2 my-0 mt-4 mb-1">
      <h5 className="font-weight-bold mb-2">Quienes somos</h5>
      <ul className="list-unstyled mb-0">
        <li>
          <p>
            <a href="#!">
              PROJECTS
              <i className="fa fa-external-link ms-2" aria-hidden="true"></i>
              </a>
          </p>
        </li>
        <li>
          <p>
            <a href="#!">
              SOBRE NOSOTROS
              <i className="fa fa-external-link ms-2" aria-hidden="true"></i>
              </a>
          </p>
        </li>
        <li>
          <p>
            <a href="http://www.axpe-blogs.com">
              BLOG
              <i className="fa fa-external-link ms-2" aria-hidden="true"></i>
              </a>
          </p>
        </li>
        <li>
          <p>
            <a href="#!">
              PREMIOS
              <i className="fa fa-external-link ms-2" aria-hidden="true"></i>
              </a>
          </p>
        </li>
      </ul>
    </div>
    <hr className="clearfix w-100 d-md-none"/>
    <div className="col-md-4 col-lg-3 mx-auto mt-md-2 my-0 mt-4 mb-1">
      <h5 className="font-weight-bold mb-2">Contacto</h5>
      <ul className="list-unstyled mb-0">
        <li>
          <p>
            <i className="fa fa-home me-2"></i>C/ Arturo Soria, 122</p>
        </li>
        <li>
          <p>
            <i className="fa fa-envelope me-2"></i>28043, Madrid</p>
        </li>
        <li>
          <p>
            <i className="fa fa-phone me-2"></i>Tel: +34 915901960</p>
        </li>
        <li>
          <p>
            <i className="fa fa-print me-2"></i>Fax: +34 915623452</p>
        </li>
      </ul>
    </div>
    <hr className="clearfix w-100 d-md-none"/>
    <div className="col-md-2 col-lg-2 text-center mx-auto mt-2 social-networks">
      <h5 className="font-weight-bold mb-2 w-100" >Siguenos</h5>
      <div className={`mb-0 ${classNames.social_networks}`}>
      <a type="button" className="btn-floating btn-fb" href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjzyfHR3qnqAhWp8uAKHesUBGwQFjAAegQIAxAB&url=https%3A%2F%2Fes-es.facebook.com%2FAxpeConsultingSpain&usg=AOvVaw2WltJt8bPl870dhJkOWNOt">
        <i className="fa fa-facebook fa-2x" aria-hidden="true"></i>
      </a>
      <a type="button" className="btn-floating btn-tw" href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiurPn33qnqAhUO8hQKHT91CncQ6F56BAgHEAI&url=https%3A%2F%2Ftwitter.com%2Faxpeconsulting%3Fref_src%3Dtwsrc%255Egoogle%257Ctwcamp%255Eserp%257Ctwgr%255Eauthor&usg=AOvVaw1Ed56YT08uf1T6qW4KeMTX">
        <i className="fa fa-twitter fa-2x" aria-hidden="true"></i>
      </a>
      <a type="button" className="btn-floating btn-gplus" href="https://www.google.com/search?client=firefox-b-d&q=axpe+consulting">
        <i className="fa fa-google fa-2x" aria-hidden="true"></i>
      </a>
      <a type="button" className="btn-floating btn-dribbble" href="https://es.linkedin.com/company/axpe-consulting">
      < i className="fa fa-linkedin fa-2x" aria-hidden="true"></i>
      </a>
      </div>
    </div>
  </div>
</div>

<div className="footer-copyright text-center pb-2">© 2023 Copyright:
  <a href="https://www.axpe.com//"> Axpe Consulting</a>
</div>
</footer>
  );
};

export default FooterAxpe;
