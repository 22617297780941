export class ImputePart {

    public employee!: number;
    public month!: number;
    public year!: number;

    public activities: Activity[] = [];
    public totals: number[] = [];
    public total: number = 0;

    public modifiable: boolean = true;

}

export class Activity {

    public project!: number;
    public concept!: number;
    public holidays!: boolean;
    public hours: number[] = [];
    public obs: string[] = [];

    public state?: string = 'GE';

    public total: number = 0;

    // list map unique key
    public key: string =  "id" + Math.random().toString(16).slice(2);
                
}
