import { takeEvery, all, takeLatest } from "redux-saga/effects";

import * as actionTypes from "../actions/actionTypes";
import {
  logoutSaga,
  checkAuthTimeoutSaga,
  authUserSaga,
  checkEnterpriseSaga,
  authCheckStateSaga,
  changePasswordSaga
} from "./auth";
import { 
  fetchActivitiesSaga, 
  putActivitiesSaga,
  putHolidaySaga 
} from "./activities";
import { 
  fetchExpensesSaga, 
  putExpensesSaga
} from "./expenses";
import { 
  fetchHolidaysSaga, 
  putHolidaysSaga
} from "./holidays";

export function* watchAuth() {
  yield all([
    takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga),
    takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
    takeEvery(actionTypes.AUTH_USER, authUserSaga),
    takeEvery(actionTypes.CHECK_ENTERPRISE, checkEnterpriseSaga),
    takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga),
    takeEvery(actionTypes.AUTH_CHANGE_PASSWORD, changePasswordSaga)
  ]);
}

export function* watchActivities() {
  yield takeLatest(actionTypes.FETCH_ACTIVITIES, fetchActivitiesSaga);
  yield takeEvery(actionTypes.PUT_ACTIVITIES, putActivitiesSaga);
  yield takeEvery(actionTypes.PUT_HOLIDAY, putHolidaySaga);
}

export function* watchExpenses() {
  yield takeLatest(actionTypes.FETCH_EXPENSES, fetchExpensesSaga);
  yield takeEvery(actionTypes.PUT_EXPENSES, putExpensesSaga);
}

export function* watchHolidays() {
  yield takeLatest(actionTypes.FETCH_HOLIDAYS, fetchHolidaysSaga);
  yield takeEvery(actionTypes.PUT_HOLIDAYS, putHolidaysSaga);
}