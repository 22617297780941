import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import FooterAxpe from './FooterAxpe';

import classes from './Layout.module.css';
import MainNavigation from './MainNavigation';
import classNames from "classnames/bind";

const cx = classNames.bind(classes);

const Layout: React.FC<{children?: React.ReactNode}> = (props) => {
  const location = useLocation();
  const printing = location.pathname !== '/print'; 
  const isHome = location.pathname == '/home'; 

  return (
    <Fragment>
     {printing &&  <MainNavigation />}
      <main 
      className={cx({
                'main': !isHome,
              })} >
        {props.children} 
      </main>
      <div className={classes.stuff}></div>
      {printing &&  <FooterAxpe/>}
    </Fragment>
  );
};

export default Layout;
