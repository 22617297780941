// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PwdDialog_dialogWidth__9tT5v {\n  width: 20rem;\n}\n\n.PwdDialog_lblRed__xpgxF {\n  color: red;\n}\n\n.PwdDialog_red__qHwEK {\n  text-align: center;\n  color: white;\n  background-color: red;\n}", "",{"version":3,"sources":["webpack://./src/pages/PwdDialog.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,kBAAA;EACA,YAAA;EACA,qBAAA;AACJ","sourcesContent":[".dialogWidth {\n    width: 20rem;\n}\n\n.lblRed {\n    color: red;\n}\n\n.red {\n    text-align: center;\n    color: white;\n    background-color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogWidth": "PwdDialog_dialogWidth__9tT5v",
	"lblRed": "PwdDialog_lblRed__xpgxF",
	"red": "PwdDialog_red__qHwEK"
};
export default ___CSS_LOADER_EXPORT___;
