export class HolidaysPart {
     
    employee!: number;
    year!: number;
    prevYear!: number;
    holidays!: MonthHolidays[];

    totalDays!: number;
    pendingDays!: number;
    enjoyedPrevYear!: number;
    inProcessDays!: number;
    enjoyed!: number;
    enjoyedYear!: number;

    manager!: string;
    
    stateIni!: string // to chech changes    
}
    

export class MonthHolidays {

    month!: string;
    days!: Holiday[];
    total!: number;
    disabled!: boolean;
}

export class Holiday {

      constructor(options = {}){
        Object.assign(this, {
            festive : false,
            weekend : false,
            check : false,
            state : '',
            visado : false,
        }, options);
    }


    check!: boolean;
    festive!: boolean;
    weekend!: boolean;
    state!: string | null;
    visado!: boolean
                
}
