import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState } from '../..';
import { Nav, Navbar, NavDropdown} from 'react-bootstrap';

import classes from './MainNavigation.module.scss';
import classNames from "classnames/bind";
import { MenuItem } from '@mui/material';
import { useState } from 'react';
import { logout } from '../../store/actions';

const cx = classNames.bind(classes);

const MainNavigation = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { token, rol, logo, enterprise, urlEnterprise } = useSelector((state: RootState) => state.auth);
  let navigate = useNavigate();

  const { pathname } = useLocation();
  let inEmployees = pathname.endsWith('employees');
  let inHolidays = pathname.endsWith('holidays');
  let inManageHolidays = pathname.endsWith('holidays/manager');

  let timer:any = useState(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    path: string,
  ) => {
    setShowDropDown(false);
    navigate(path);
    event.preventDefault();
    event.stopPropagation();
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
  }

  const handleOpen = (
    event: any
  ) => {
    console.log('handleOpen');
    setShowDropDown(true);
    if (timer) {
      clearInterval(timer);
    }
    timer = setTimeout(() => {
      setShowDropDown(false)
      timer = null;
    }, 4000);
  }

  const logoutHandler= (event: any) => {
    dispatch(logout());
    navigate('/');
  };


  return (
    <header className={classes.header}>
      <Navbar collapseOnSelect  expand="lg" className='navbar navbar-dark navbar-expand-lg navbar-light pe-4'>
        <Navbar.Toggle />
        <Navbar.Collapse>
        <Nav className={`${classes.nav} navbar-nav mr-auto mt-2 mt-lg-0`}>
          {token && <Nav.Item className={classes.nav_item}>
            <NavLink to='/home' className={(navData) => navData.isActive ? "nav-selected" : "" } >
            {t('Home')}
            </NavLink>
          </Nav.Item>}
          {token && <Nav.Item className={classes.nav_item}>
            <NavLink to='/activities' className={(navData) => navData.isActive ? "nav-selected" : "" } >
              {t('Actividades')}
            </NavLink>
          </Nav.Item>}
          {token && <Nav.Item className={classes.nav_item}>
            <NavLink to='/expenses' className={(navData) => navData.isActive ? "nav-selected" : "" } >
            {t('Gastos')}
            </NavLink>
          </Nav.Item>}
          {token && <Nav.Item className={classes.nav_item}>
            <a href={urlEnterprise} target="_blank">{t('Fichajes')}</a>
          </Nav.Item>}
          
          {token && <Nav.Item className={classes.nav_item}>
            <NavLink to='/holidays' className={(navData) => navData.isActive && inHolidays ? "nav-selected" : "" } >
            {t('Vacaciones')}
            </NavLink>
          </Nav.Item>}


          {token && (rol === 1 || rol === 3  || rol === 4) && 

          <NavDropdown title={t('Empleados')} id="basic-nav-dropdown" 
                className={cx({
                  'nav_dropdown': true,
                  'active': inEmployees || inManageHolidays
                })}
                show={showDropDown} onClick={handleOpen}>
              <MenuItem  className={classes.nav_dropdown_item}
                selected={inEmployees} 
                onClick={(event) => handleMenuItemClick(event, "/employees")}>
                {t('Partes de Actividades')}
               </MenuItem >
              <MenuItem   className={classes.nav_dropdown_item} 
                selected={inManageHolidays} 
                onClick={(event) => handleMenuItemClick(event, "/holidays/manager" )}>
                  {t('Solicitudes de Vacaciones')}
              </MenuItem >
            </NavDropdown>
          }

          {token && <Nav.Item className={classes.nav_item} >
            <NavLink onClick={logoutHandler} to='#' >
            {t('Desconectar')}
            </NavLink>
          </Nav.Item>}

        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <div className={cx({
          'logo': true,
          'ps-4': true,
          'logo-right': enterprise === 22
        })} >
      {logo && <img src={logo} height="80" alt="" loading="lazy"></img>}
    </div>
    </header>
  );
};

export default MainNavigation;
