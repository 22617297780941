import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Activity } from '../../types/Activity';

import classes from './ActivityItem.module.scss';

import classNames from "classnames/bind";
import { Fragment, useState } from 'react';
import { putActivity, deleteActivity } from '../../store/actions/activities';
import { RootState } from '../..';
import { stateDesc } from '../../lib/Utils';

const cx = classNames.bind(classes);


const ActivityItem: React.FC<{index: number; activ: Activity; 
          projects: any[], concepts: any; weekendDays: number[], holidays: number[];
          onProjectChange: (index: any) => void;
          onOpen: (month: any, day: any) => void;
          children?: React.ReactNode}> = (props) => {
  const { vacationDays } = useSelector((state: RootState) => state.activ);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  var rowClass = cx({
    'activ': true,
    'anchoAuto': true,
    'no-edit-allowed blur': props.activ.state === 'EE' || props.activ.state === 'VG' || props.activ.state === 'EF' || props.activ.state === 'VF'  || props.activ.state === 'VA'
  });
  var stateClass = cx({
    'draft': props.activ.state === 'GE', 
    'sent': props.activ.state === 'EE',
    'visado': props.activ.state === 'VG' || props.activ.state === 'VF' || props.activ.state === 'EF' || props.activ.state === 'VA',
    'refused': props.activ.state === 'RG' || props.activ.state === 'RF'
  });

  const [selectedProject, setSelectedProject] = useState(props.activ.project);
  const [selectedConcept, setSelectedConcept] = useState(props.activ.concept);

  const handleProjectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProject(parseInt(event.target.value));
    props.activ.project = parseInt(event.target.value);
    props.activ.concept = null as any;
    props.activ.holidays = false;
    dispatch(putActivity(props.index, props.activ));
    props.onProjectChange(props.index);
  };

  const handleConceptChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedConcept(parseInt(event.target.value));
    props.activ.concept = parseInt(event.target.value);
    dispatch(putActivity(props.index, props.activ));
  };

  const deleteHandler = () =>{
    dispatch(deleteActivity(props.index));
  }

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>, index: number) =>{
    props.activ.hours[index] = parseFloat(event.target.value);
    dispatch(putActivity(props.index, props.activ));
  }

  const focusHandler = (event: React.FocusEvent<HTMLInputElement>) =>{
    // Select content on focus
    event.target.select();
  }

  const openHandler = (event: any, index: number) =>{
    props.onOpen(props.index, index);
  }

  const isHoliday = (index: number) :boolean => {
    return vacationDays.includes(index);
  }

  let concepts = [];
  if (props.concepts[props.activ.project]) {
    concepts = props.concepts[props.activ.project];
  }
  if (concepts.length === 1 && !props.activ.concept) {
      props.activ.concept = concepts[0].value;
      dispatch(putActivity(props.index, props.activ));
      setSelectedConcept(props.activ.concept);
  }
        
  return (
    <tr className={rowClass}>
      <td className="mv-grid-title-td-hide">{t('Proyecto')}</td>
      <td>
        <select onChange={handleProjectChange} value={selectedProject}
          className={classes.clsSelecProj} title={t('Select project')}>
        <option key="-1" value=''></option>
        {props.projects.map((option, index) => {
            return <option key={option.value}  value={option.value}>
                {option.label}
            </option>
        })}
      </select>
      </td>
      <td className="mv-grid-title-td-hide ">{t('Concepto')}</td>
      <td>
        <select onChange={handleConceptChange} value={selectedConcept}
          className={classes.clsSelec} title={t('Select concept')}>
          <option key="-1" value=''></option>
          {concepts.map((option:any, index:number) => {
            return <option key={option.value}  value={option.value}>
                {option.label}
            </option>
        })}
      </select>
      </td>

      {props.activ.hours.map((horas: number, index) => (
        <Fragment key={index}>
        <td className="mv-grid-title-td-hide ">D&iacute;a&nbsp;{ index + 1 }</td>
        <td className={cx({
                'blue': true,
                'rounded': true,
                'text-center': true,
                'bgYellow': props.weekendDays.includes(index + 1) || props.holidays.includes(index + 1),
                'no-edit-allowed blur': props.activ.holidays || isHoliday(index)
              })} data-day={'S'}>
          <input type="number" step="1" size={2} maxLength={2} min={0} max={24} placeholder="0" 
              value={horas} onFocus={focusHandler} onChange={(e) => inputHandler(e, index)} onDoubleClick={(e) => openHandler(e, index)}
              disabled = {props.activ.holidays || isHoliday(index)}
              className={cx({
                'mat-input-element': true,
                'blue': horas > 0,
                'black': horas === 0
              })}/>
        </td> 
        </Fragment>
        ))}
      <td className="mv-grid-title-td-hide ">{t('Estado')}</td>
      <td className={`${stateClass} text-center rounded`}>
      <input type="text" size={20} maxLength={100}
            className={`${classes.txt_estado}`}
            value= {stateDesc(props.activ.state!)} readOnly={true} 
						 aria-label="Estado"/>
      </td>

      <td className="mv-grid-title-td-hide "></td>   
      <td className={`${stateClass} ${classes.total} fw-bold`}> {props.activ.total}</td>

      <td className="mv-grid-title-td-hide "></td>   
      <td className={`${stateClass} widthColBtn btnDelete`}
            onClick={deleteHandler} >
        <span>
        <i className="fa fa-remove fa-2x"></i>
        </span>
      </td>
    </tr>

  );
};

export default ActivityItem;
