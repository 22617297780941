import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ActivityItem from './ActivityItem';
import classes from './ActivitiesList.module.scss';
import { ImputePart } from '../../types/Activity';
import { putHoliday } from '../../store/actions';
import classNames from "classnames/bind";
import { OptionType } from '../../types/Props';
import { DayDialog } from './DayDialog';

const cx = classNames.bind(classes);

const ActivitiesList: React.FC<{part: ImputePart;
          projects: OptionType[], concepts: any; holidays: any;
          children?: React.ReactNode}> = (props) => {
  const [monthDays, setMonthDays] = useState([]);
  const [weekendDays, setWeekendDays] = useState([]);
  const [open, setOpen] = useState(false);
  const [iActiv, setIActiv] = useState(null as any);
  const [day, setDay] = useState(null as any);
  
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const projectChangeHandler = (index: number) => {
    dispatch(putHoliday('token!!!', props.part, index));
  };

  useEffect(() => {
    // Month days
    var first = new Date(props.part.year, props.part.month, 1);
    var last = new Date(props.part.year, props.part.month + 1, 0);
    let monthDays:any = [];
    let weekendDays:any = [];
    
    for (var j=first.getDate(); j<=last.getDate(); j++){
      var diaFD = new Date(props.part.year, props.part.month, j);
      if (diaFD.getDay() === 6 || diaFD.getDay() === 0){
        weekendDays.push(j);
      }
      monthDays.push(j);
    }

    setMonthDays(monthDays);
    setWeekendDays(weekendDays);
  }, [props.part.year, props.part.month]);
  
  const openHandler = (iActiv_: number, day_: number) => {
    setIActiv(iActiv_);
    setDay(day_);
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <Fragment>
    <DayDialog open={open} activIndex={iActiv} day={day}
      onOkDialog={handleOk} onCloseDialog={handleClose}></DayDialog>

      <table className={`${classes.grid_activities} responsive`} cellSpacing="1" cellPadding={"3"}>
      <thead>
        <tr className={`${classes.fondoAzul} mv-grid-title-tr`}>
          <th >{t('Proyecto')}</th>
          <th >{t('Concepto')}</th>
          {monthDays.map((dia: any, index) => (
          <th key={index}>{dia}</th>
          ))}
          <th >{t('Estado')}</th>
          <th >{t('Total')}</th>
        </tr>
      </thead>
      <tbody>

        {props.part.activities.map((activ: any, index) => (
          <ActivityItem
            key={activ.key}
            index={index}
            activ={activ}
            projects={props.projects} concepts={props.concepts} 
            holidays={props.holidays}
            weekendDays={weekendDays}
            onProjectChange={projectChangeHandler}
            onOpen={openHandler}
          />
        ))}
        <tr className={classes.totalrow}>
          <td className="hide-xs" ></td>
          <td className={`w30_xs ${classes.lblTotal}`} >{t('Total')}</td>
          {props.part.totals.map((total: any, index) => (
              <td key={index} className={cx({
                'hide-xs': true,
                'red': total === 0
              })}>{total}</td>
          ))}
          <td className={`${classes.total} fw-bold d-block d-lg-none`}> {props.part.total}</td>
        </tr>
      </tbody>
    </table>
    <div className='d-none d-lg-block'>
    <span className={classes.w18rem} />
    <span className={`${classes.w10rem} fw-bold text-end`}  >{t('Total horas')}:</span>
    <span className='d-inline-block fw-bold text-start'>&nbsp; {props.part.total} </span>
    </div>
    </Fragment>
)};

export default ActivitiesList;
