// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Expenses_msg__mYHcu {\n  margin-bottom: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/pages/expenses/Expenses.module.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF","sourcesContent":["\n\n.msg {\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msg": "Expenses_msg__mYHcu"
};
export default ___CSS_LOADER_EXPORT___;
