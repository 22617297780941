export class ExpensesPart {

    public employee!: number;
    public month!: number;
    public year!: number;

    public expenses: Expense[] = [];
    public total: number = 0;

    public modifiable: boolean = true;

}

export class Expense {

    public project!: number;
    public expenseType!: number;
    
    public dateRange!: (Date | null)[];
    public nmUnits!: number;
    public nmAmount!: number;
    public nmTotal!: number;
    public dsDescription!: string;

    public state?: string = 'GE';

    // list map unique key
    public key: string =  "id" + Math.random().toString(16).slice(2);
                
}
