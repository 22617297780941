// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';

i18n.use(LanguageDetector).use(initReactI18next).init({
  detection: {
    order: ['navigator'],
    caches: ['localStorage', 'cookie'],
  },
  resources: {
    en: {
      translation: translationEN
    },
    es: {
      translation: translationES
    },
  },
  fallbackLng: "es",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
