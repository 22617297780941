import { Activity, ImputePart } from "../../types/Activity";
import * as actionTypes from "./actionTypes";

export const putHoliday = (token: string, part: ImputePart, index: number) => {
  return {
    type: actionTypes.PUT_HOLIDAY,
    part,
    index,
    token
  };
};

export const putHolidaySuccess = (index: number, days: number[], holidays: boolean) => {
  return {
    type: actionTypes.PUT_HOLIDAY_SUCCESS,
    index,
    days,
    holidays
  };
};

export const putActivitiesSuccess = (state: string, msg: string) => {
  return {
    type: actionTypes.PUT_ACTIVITIES_SUCCESS,
    state,
    msg
  };
};

export const putActivitiesFail = (error: string) => {
  return {
    type: actionTypes.PUT_ACTIVITIES_FAIL,
    error
  };
};

export const putActivitiesStart = () => {
  return {
    type: actionTypes.PUT_ACTIVITIES_START
  };
};

export const putActivities = (token: string, part: ImputePart, state: string, conceptsMap: any, forceSend: boolean = false) => {
  return {
    type: actionTypes.PUT_ACTIVITIES,
    part,
    state,
    token,
    conceptsMap,
    forceSend
  };
};

export const activitiesInit = () => {
  return {
    type: actionTypes.ACTIVITIES_INIT
  };
};

export const fetchActivitiesSuccess = (part: ImputePart, projectCods: number[], vacationDays: number[]) => {
  return {
    type: actionTypes.FETCH_ACTIVITIES_SUCCESS,
    part,
    projectCods,
    vacationDays
  };
};

export const fetchActivitiesFail = (error: string) => {
  return {
    type: actionTypes.FETCH_ACTIVITIES_FAIL,
    error
  };
};

export const fetchActivitiesStart = (employee: number, year: number, month: number) => {
  return {
    type: actionTypes.FETCH_ACTIVITIES_START,
    employee,
    year,
    month
  };
};

export const fetchActivities = (token: string, employee: number, year: number, month: number) => {
  return {
    type: actionTypes.FETCH_ACTIVITIES,
    token,
    employee,
    year,
    month
  };
};

export const addActivity = (year: number, month: number) => {
  return {
    type: actionTypes.ADD_ACTIVITY,
    year,
    month
  };
};

export const putActivity = (index: number, activity: Activity) => {
  return {
    type: actionTypes.PUT_ACTIVITY,
    index,
    activity
  };
};

export const deleteActivity = (index: number) => {
  return {
    type: actionTypes.DELETE_ACTIVITY,
    index
  };
};

