import { HolidaysPart } from "../../types/Holiday";
import * as actionTypes from "./actionTypes";

export const putHolidaysSuccess = (msg: string) => {
  return {
    type: actionTypes.PUT_HOLIDAYS_SUCCESS,
    msg
  };
};

export const putHolidaysFail = (error: string) => {
  return {
    type: actionTypes.PUT_HOLIDAYS_FAIL,
    error
  };
};

export const putHolidaysStart = () => {
  return {
    type: actionTypes.PUT_HOLIDAYS_START
  };
};

export const putHolidays = (token: string, part: HolidaysPart, state: string) => {
  return {
    type: actionTypes.PUT_HOLIDAYS,
    part,
    state,
    token
  };
};

export const holidaysInit = (employee: number, year: number) => {
  return {
    type: actionTypes.HOLIDAYS_INIT,
    employee,
    year
  };
};

export const fetchHolidaysSuccess = (year: number, holidays: any, disable: any, festiveDays: any) => {
  return {
    type: actionTypes.FETCH_HOLIDAYS_SUCCESS,
    year,
    holidays,
    disable,
    festiveDays
  };
};

export const fetchHolidaysFail = (error: string) => {
  return {
    type: actionTypes.FETCH_HOLIDAYS_FAIL,
    error
  };
};

export const fetchHolidaysStart = (employee: number, year: number) => {
  return {
    type: actionTypes.FETCH_HOLIDAYS_START,
    employee,
    year
  };
};

export const fetchHolidays = (token: string, employee: number, year: number) => {
  return {
    type: actionTypes.FETCH_HOLIDAYS,
    token,
    employee,
    year,
  };
};

export const checkHoliday = (indexMonth: number, indexDay: number, check: boolean) => {
  return {
    type: actionTypes.CHECK_HOLIDAY,
    indexMonth,
    indexDay,
    check
  };
};
