import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

import { ImputePart, Activity } from '../../types/Activity';
import { imputationsTotals, getMonth } from '../../lib/Utils';
import { AnyAction } from 'redux';

// Define a type for the slice state
interface ImputeState {
    part: ImputePart,
    error: string | null,
    loading: boolean,
    projectCods: number[],
    vacationDays: number[]
  }

const initialState: ImputeState = {
    part: new ImputePart(),
    loading: false,
    error: null,
    projectCods: [],
    vacationDays: []
};

const statesPending = ['GE', 'RG', 'RF'];

const activitiesInit = ( state: ImputeState, action: AnyAction  ) => {
    return updateObject( state, { projectCods: [], vacationDays: [] } );
};

const putActivity = ( state: ImputeState, action: AnyAction  ) => {
    if (action.activity.project) {
        state.projectCods = state.projectCods.concat(action.activity.project);
    }
    action.activity.hours.forEach(function (value: number, i: number) {
        if (!value && value !== 0) {
            action.activity.hours[i] = 0;
        }
    });
    state.part.activities[action.index] = action.activity;
    imputationsTotals(state.part);
    return updateObject( state, {
        part: state.part,
        projectCods: state.projectCods
    } );
};

const deleteActivity = ( state: ImputeState, action: AnyAction  ) => {
    state.part.activities.splice(action.index, 1);
    imputationsTotals(state.part);
    return updateObject( state, {
        part: state.part
    } );
};

const addActivity = ( state: ImputeState, action: AnyAction  ) => {
    let actividad = newActivity(state.part.year, state.part.month);
    state.part.activities = state.part.activities.concat( actividad);

    return updateObject( state, {
        part: state.part
    } );
};

const putActivitiesStart = ( state: ImputeState, action: AnyAction  ) => {
    return updateObject( state, { 
        loading: true,
        error: null
    } );
};

const putActivitiesSuccess = ( state: ImputeState, action: AnyAction  ) => {
    if (action.state === 'EE')  {
        state.part.activities.forEach(item => {
            if (statesPending.includes(item.state!)) item.state = 'EE';
        });
        state.part.modifiable = false;
    }
    return updateObject( state, {
        loading: false,
        error: action.msg,
        part: state.part
    } );
};

const putHolidaySuccess = ( state: ImputeState, action: AnyAction  ) => {
    state.part.activities[action.index].holidays = action.holidays;
    state.part.activities[action.index].hours.fill(0);
    for (let holiday of action.days) {
        state.part.activities[action.index].hours[holiday] = 8;
      } 
    return updateObject( state, {
        loading: false,
        part: state.part
    } );
};

const putActivitiesFail = ( state: ImputeState, action: AnyAction  ) => {
    return updateObject( state, { 
        loading: false,
        error: action.error
    } );
};

const fetchActivitiesStart = ( state: ImputeState, action: AnyAction  ) => {
    return updateObject( state, 
        { loading: true, 
            error: null, 
            projectCods: [],
            vacationDays: [],
            part: initImputePart(action.employee, action.month, action.year) } );
};

const fetchActivitiesSuccess = ( state: ImputeState, action: AnyAction  ) => {
    if (action.part.activities.length === 0) {
        // empty list
        return updateObject( state, {
            loading: false,
            error: null,
            vacationDays: action.vacationDays
        } );
    }
    return updateObject( state, {
        part: action.part,
        loading: false,
        error: null,
        projectCods: action.projectCods,
        vacationDays: action.vacationDays
    } );
};

const fetchActivitiesFail = ( state: ImputeState, action: AnyAction  ) => {
    return updateObject( state, { 
        loading: false,
        error: action.error
    } );
};

const reducer = ( state = initialState, action: AnyAction  ) => {
    switch ( action.type ) {
        case actionTypes.ACTIVITIES_INIT: return activitiesInit( state, action );
        case actionTypes.PUT_ACTIVITIES_START: return putActivitiesStart( state, action );
        case actionTypes.PUT_ACTIVITIES_SUCCESS: return putActivitiesSuccess( state, action )
        case actionTypes.PUT_HOLIDAY_SUCCESS: return putHolidaySuccess( state, action )
        case actionTypes.PUT_ACTIVITIES_FAIL: return putActivitiesFail( state, action );
        case actionTypes.FETCH_ACTIVITIES_START: return fetchActivitiesStart( state, action );
        case actionTypes.FETCH_ACTIVITIES_SUCCESS: return fetchActivitiesSuccess( state, action );
        case actionTypes.FETCH_ACTIVITIES_FAIL: return fetchActivitiesFail( state, action );
        case actionTypes.ADD_ACTIVITY: return addActivity( state, action );
        case actionTypes.PUT_ACTIVITY: return putActivity( state, action );
        case actionTypes.DELETE_ACTIVITY: return deleteActivity( state, action );
        default: return state;
    }
};

export default reducer;


const initImputePart = (employee: number, year: number, month: number) => {
    let ret;
    ret = new ImputePart();
    ret.employee = employee;
    ret.month = month;
    ret.year = year;
  
    // Una actividad vacía por defecto
    let actividad = newActivity(year, month);
  
    ret.total = 0;
    ret.totals = getMonth(month, year);
  
    ret.activities.push(actividad); 
    return ret;
  }
  
  const newActivity = (year: number, month: number) => {
    let actividad = new Activity();
  
    actividad.state = 'GE';
    actividad.hours = getMonth(month, year);
    for(var i=0; i<=actividad.hours.length; i++){
      actividad.obs[i] = '';
    }
    return actividad;
  }
